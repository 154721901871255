<template>
  <v-app>
    <!-- <vue-particles
      color="#240b36"
      :particleOpacity="0.7"
      :particlesNumber="50"
      shapeType="star"
      :particleSize="4"
      linesColor="#c31432"
      :linesWidth="2"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles> -->
    <v-container class="container">
      <v-card class="grid" :elevation="11" width="95%">
        <v-card-title>Bookings</v-card-title>
        <v-row>
          <v-col cols="12" md="4">
            <v-card-text class="text">Filter by Month :</v-card-text>
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="filterParams.month"
                  label="Month"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                  dense
                  outlined
                  solo
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterParams.month"
                no-title
                scrollable
                type="month"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <v-card-text class="text">Filter by Start Date :</v-card-text>
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="filterParams.day"
                  label="Day"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                  dense
                  outlined
                  solo
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterParams.day"
                no-title
                scrollable
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <v-card-text class="text">Number Plate : </v-card-text>
            <v-select
              :items="plates"
              prepend-icon="mdi-car"
              v-model="filterParams.plateNumber"
              solo
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center" style="padding-bottom: 10px !important;">
          <v-col :cols="4" align="center">
            <v-btn class="button" dark @click="filter(filterParams)">
              <v-icon
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
              >
                mdi-filter-outline mdi-18px
              </v-icon>
              Filter
            </v-btn>
          </v-col>
          <v-col :cols="4" align="center">
            <v-btn class="button" dark @click="resetFilters">
              <v-icon
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
              >
                mdi-lock-reset mdi-18px
              </v-icon>
              Reset Filter
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-card-text class="text"
              >Total Booking Sum : RM {{ filteredBookingsTotal }}</v-card-text
            ></v-col
          >
          <v-col cols="12" md="4">
            <v-card-text class="text"
              >Total Days Booked : {{ daysBooked }}</v-card-text
            ></v-col
          >
        </v-row>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="filteredBookings"
            :search="search"
            :loading="loading"
            :no-data-text="'No bookings found.'"
            :footer-props="{
              itemsPerPageOptions: [20, 40, 60, 80, 100],
              'items-per-page-text': 'Bookings per page:',
              'show-current-page': true,
              'show-first-last-page': true,
            }"
            :no-results-text="noResultsText"
            :loading-text="loadingText"
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import DataService from "../services/dataService.js";
const dataService = new DataService();
import moment from "moment";
export default {
  data() {
    return {
      bookings: [],
      filterParams: {
        month: null,
        day: null,
        dateRange: null,
        plateNumber: null,
      },
      menu1: null,
      menu2: null,
      filteredBookings: [],
      headers: [
        { text: "Plate Number", align: "center", value: "number_plate" },
        { text: "Date Start", align: "center", value: "date_start" },
        { text: "Date End", align: "center", value: "date_end" },
        { text: "Mobile Number", align: "center", value: "mobile" },
        { text: "ID", align: "center", value: "id_number" },
        { text: "Price", align: "center", value: "total_price" },
        { text: "Name", align: "center", value: "name" },
        { text: "Agent", align: "center", value: "creator" },
      ],
      search: "",
      loading: false,
      noResultsText: "No bookings found.",
      loadingText: "Loading bookings...",
      total: 0,
      plates: [],
    };
  },
  mounted: function () {
    this.getBookings();
  },

  computed: {
    filteredBookingsTotal() {
      let total = 0;
      this.filteredBookings.forEach((booking) => {
        if (booking.status == "New") {
          total += Number(booking.total_price);
        }
      });
      if (isNaN(total)) {
        return 0;
      } else {
        return total;
      }
    },

    daysBooked() {
      let total = 0;
      this.filteredBookings.forEach((booking) => {
        if (booking.status == "New") {
          total += Number(booking.booking_duration);
        }
      });
      return total;
    },
  },

  methods: {
    async getBookings() {
      if (this.$store.state.user.roles[0] == "admin") {
        this.$setLoader();
        await dataService.getAgreements().then((res) => {
          // console.log(this.bookings);
          for (let booking of res.data.agreements) {
            booking.date_start = booking.date_start.slice(0, 10);
            booking.date_end = booking.date_end.slice(0, 10);


            if (booking.status == "New") {
              this.plates.push(booking.number_plate)
              this.bookings.push(booking);
            }
          }
        });

        this.$disableLoader();
      } else {
        alert("USER NOT AUTHORIZED TO VIEW THIS PAGE");
        this.$router.push({ path: `/` });
      }
    },

    filter(filterParams) {
      let filteredBookings = this.bookings;

      if (filterParams.plateNumber && filterParams.month && filterParams.day) {
        // Filter by plate number, month, and day
        filteredBookings = filteredBookings.filter(
          (booking) =>
            booking.number_plate === filterParams.plateNumber &&
            moment(booking.date_start).isSame(filterParams.day, "day") &&
            booking.date_start.slice(5, 7) === filterParams.month.split("-")[1]
        );
      } else if (filterParams.plateNumber && filterParams.month) {
        // Filter by plate number and month
        filteredBookings = filteredBookings.filter(
          (booking) =>
            booking.number_plate === filterParams.plateNumber &&
            booking.date_start.slice(5, 7) === filterParams.month.split("-")[1]
        );
      } else if (filterParams.plateNumber && filterParams.day) {
        // Filter by plate number and day
        filteredBookings = filteredBookings.filter(
          (booking) =>
            booking.number_plate === filterParams.plateNumber &&
            moment(booking.date_start).isSame(filterParams.day, "day")
        );
      } else if (filterParams.month && filterParams.day) {
        // Filter by month and day
        filteredBookings = filteredBookings.filter(
          (booking) =>
            moment(booking.date_start).isSame(filterParams.day, "day") &&
            booking.date_start.slice(5, 7) === filterParams.month.split("-")[1]
        );
      } else if (filterParams.plateNumber) {
        // Filter by plate number only
        filteredBookings = filteredBookings.filter(
          (booking) => booking.number_plate === filterParams.plateNumber
        );
      } else if (filterParams.month) {
        // Filter by month only
        filteredBookings = filteredBookings.filter(
          (booking) =>
            booking.date_start.slice(5, 7) === filterParams.month.split("-")[1]
        );
      } else if (filterParams.day) {
        // Filter by day only
        filteredBookings = filteredBookings.filter((booking) =>
          moment(booking.date_start).isSame(filterParams.day, "day")
        );
      }

      this.filteredBookings = filteredBookings;
    },

    resetFilters() {
      this.filterParams.day = null;
      this.filterParams.month = null;
      this.filterParams.plateNumber = null;
    },
  },
};
</script>

<style scoped lang="css">
.container {
  /* margin-top: 5%; */
  max-width: none !important;
}

#particles-js {
  background-color: #f9ece0 !important;
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 0 !important;
}
</style>
